import axios from 'axios';

const DEV_GRAPHQL_DOMAIN = "https://api.dev.ridi.io";
const DEV_ACCOUNT_DOMAIN = "https://account.dev.ridi.io/";
const PROD_GRAPHQL_DOMAIN = "https://api.ridibooks.com";
const PROD_ACCOUNT_DOMAIN = "https://account.ridibooks.com/";
const getRunMode = (runMode) => runMode || "production";
const isDev = () => getRunMode(process.env.RUN_MODE) === "development";
const getGraphQLDomain = () => {
  if (typeof window === "undefined" && process.env.SERVER_SIDE_BACKENDS) {
    return process.env.SERVER_SIDE_BACKENDS;
  }
  return isDev() ? DEV_GRAPHQL_DOMAIN : PROD_GRAPHQL_DOMAIN;
};
const getAccountDomain = (isDevEnv) => isDevEnv ? DEV_ACCOUNT_DOMAIN : PROD_ACCOUNT_DOMAIN;
const axiosInstance = axios.create({
  baseURL: "".concat(getGraphQLDomain(), "/graphql"),
  headers: {
    "content-type": "application/json"
  },
  method: "POST",
  withCredentials: true,
  timeout: isDev() ? void 0 : 2e4
});
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config: axiosConfig, response } = error;
    if (!response || response.status !== 401) {
      throw error;
    }
    if (axiosConfig && axiosConfig.retryAuth === false) {
      throw error;
    }
    if (typeof window === "undefined") {
      throw error;
    }
    try {
      await axiosInstance({
        baseURL: getAccountDomain(isDev()),
        method: "POST",
        url: "/ridi/token",
        retryAuth: false
      });
    } catch {
      throw error;
    }
    return axiosInstance({
      ...axiosConfig,
      headers: { ...axiosConfig == null ? void 0 : axiosConfig.headers },
      retryAuth: false
    });
  }
);
const isFetchHeaders = (headers) => !Array.isArray(headers) && typeof headers.forEach === "function";
const normalizeHeaders = (headers) => {
  const headerObject = {};
  if (Array.isArray(headers)) {
    headers.forEach(([key, value]) => {
      headerObject[key] = value;
    });
    return headerObject;
  }
  if (isFetchHeaders(headers)) {
    headers.forEach((value, key) => {
      headerObject[key] = value;
    });
    return headerObject;
  }
  return headers;
};
const isAxiosError = (err) => !!err && typeof err === "object" && "isAxiosError" in err && err.isAxiosError === true;
const isGraphQLError = (data) => !!data && typeof data === "object" && "errors" in data && Array.isArray(data.errors);
const isAxiosErrorGraphQLError = (err) => {
  var _a;
  return isGraphQLError((_a = err.response) == null ? void 0 : _a.data);
};
const isPersistedQueryError = (data) => data.errors.find((error) => {
  var _a;
  return ((_a = error.extensions) == null ? void 0 : _a.code) === "PERSISTED_QUERY_NOT_FOUND";
});
const assertAndGetGraphQLData = (res) => {
  if (!res.data) {
    console.error(res);
    throw new TypeError("Invalid response. response does not have data property");
  }
  return res.data;
};
const prettyGraphQLError = (error) => {
  var _a, _b, _c;
  const prettifiedError = error;
  prettifiedError.query = String((_a = error.config) == null ? void 0 : _a.data);
  prettifiedError["-"] = "-".repeat(100);
  prettifiedError.reasons = (_c = (_b = error.response) == null ? void 0 : _b.data.errors.map((reason) => reason.message).join("  ")) != null ? _c : "";
  return prettifiedError;
};
const request = async ({ data, timeout, headers }) => {
  const res = await axiosInstance.request({
    data,
    timeout,
    headers: headers && normalizeHeaders(headers)
  }).catch((err) => {
    if (isAxiosError(err) && isAxiosErrorGraphQLError(err)) {
      return Promise.reject(prettyGraphQLError(err));
    }
    return Promise.reject(err);
  });
  return res.data;
};
const fetchOperationWithAPQ = async (opts) => {
  const { importQuery, hash, variables, headers, timeout } = opts;
  const extensions = {
    persistedQuery: { version: 1, sha256Hash: hash }
  };
  const res = await request({
    data: { extensions, variables },
    timeout,
    headers
  });
  if (!isGraphQLError(res) || !isPersistedQueryError(res)) {
    return assertAndGetGraphQLData(res);
  }
  const query = await importQuery();
  const data = await request({
    data: { query, extensions, variables },
    timeout,
    headers
  });
  return assertAndGetGraphQLData(data);
};
const fetcher = (query, variables, options) => async (queryFunction) => {
  var _a;
  const res = await request({
    data: { query, variables },
    timeout: (_a = queryFunction == null ? void 0 : queryFunction.meta) == null ? void 0 : _a.timeout,
    headers: options
  });
  return assertAndGetGraphQLData(res);
};

export { fetchOperationWithAPQ, fetcher };
